export default interface IPageEntry {
    pageName: string;
    pageType: EPageType;
    pageHtml: string;
    showInNav: boolean;
    href: string;
}

export enum EPageType {
    BASIC = "BASIC",
    EXTENDED = "EXTENDED"
}