import * as React from 'react';
import YouTube from 'react-youtube';
import { MainProps } from '../MainComponent';

export class RootPage extends React.PureComponent<MainProps, {}> {

    private page:string;

    constructor(props:MainProps) {
        super(props);
    }

    componentDidMount() {
        fetch("/data/pages/special/rootPage.html")
        .then(resp => resp.text())
        .then((resp:string) =>{
            this.page = resp;
            this.forceUpdate();
        });
    }
    render() {
        return (
        <section id="main-content" dangerouslySetInnerHTML={{__html: this.page}}>
        </section>
        );
    }

}