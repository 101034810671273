import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import { Main } from './components/MainComponent';
import IBasicData from './interfaces/IBasicData';


fetch("/data/configs/data_cz.json")
.then(resp => resp.json())
.then((response:IBasicData) =>{
    ReactDOM.render(
        <BrowserRouter>
            <Main basicData={response}/>
        </BrowserRouter>,
        document.getElementById("app-root")
    );
});

