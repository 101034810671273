import * as React from 'react';

export class MainFooter extends React.PureComponent<{}, {}> {

    footer:string = "";

    componentDidMount() {
        fetch("/data/pages/special/footer.html")
        .then(resp => resp.text())
        .then((resp:string) =>{
            this.footer = resp;
            this.forceUpdate();
        });
    }

    render() {
        return (
            <footer id="main-footer">
            <div className="container" dangerouslySetInnerHTML={{__html: this.footer}}>
                
            </div>
        </footer>
        );
    }
}