import * as React from 'react';
import { MainHeader } from './MainHeaderComponent';
import { RootPage } from './pages/RootPageComponent';
import IBasicData from '../interfaces/IBasicData';
import CookieBanner from 'react-cookie-banner/lib';
import { NotFoundPageComponent } from './pages/404PageComponent';
import { Route, Switch } from 'react-router-dom';
import { BasePage } from './pages/PageComponent';
import IPageEntry, { EPageType } from '../interfaces/IPageEntry';
import IServiceEntry from '../interfaces/IServiceEntry';
import { MainFooter } from './MainFooterComponent';

export interface MainProps {
    basicData:IBasicData;
}

export class Main extends React.PureComponent<MainProps, {}> {

    activeRoute:string;

    constructor(props:MainProps) {
        super(props);
        this.activeRoute = window.location.pathname;
    }

    render() {
        return (
            <div className={"animated " + this.props.basicData.entryAnimation}>
                
                <CookieBanner
                    message={this.props.basicData.localization.cookiesWarning}
                    onAccept={()=> {}}
                    buttonMessage={this.props.basicData.localization.cookiesBtnOk}
                    cookie="cookies-ok"
                    styles={{}} />
                    <MainHeader activePage={this.activeRoute} basicData={this.props.basicData}/>
                    
                    <Switch>
                        <Route exact path="/" render={(props) => {
                            return (
                                <RootPage basicData={this.props.basicData} />
                            );
                        }}/>
                        <Route path={"/str/:page"} render={(props) => {
                            let page:IPageEntry = this.getPageByHref(props.match.params.page);
                            if (page === null) { return (<NotFoundPageComponent basicData={this.props.basicData} />); }
                            return (
                                <BasePage basicData={this.props.basicData} pageType={page.pageType} pageName={page.pageName} pageHtml={page.pageHtml}/>
                            );
                        }}/>
                        <Route component={NotFoundPageComponent}/>
                    </Switch>

                    <MainFooter/>
                
            </div>
        )
    }

    public getPageByHref(href:string):IPageEntry {
        for (let page of this.props.basicData.pages) {
            if (href === page.href) {
                return page;
            }
        }

        return null;
    }

    public getServiceByHref(href:string):IServiceEntry {
        for (let page of this.props.basicData.services) {
            if (href === page.href) {
                return page;
            }
        }

        return null;
    }

}