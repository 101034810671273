import * as React from 'react';
import { MainProps } from '../MainComponent';
import IPageEntry, { EPageType } from '../../interfaces/IPageEntry';

interface BasePageProps extends MainProps {
    pageType:EPageType,
    pageName:string,
    pageHtml:string,
}

export class BasePage extends React.PureComponent<BasePageProps, {}> {

    currentPage:IPageEntry;
    private sidebar:string;

    constructor(props: BasePageProps) {
        super(props);

        this.currentPage = {} as IPageEntry;
    }

    componentWillMount() {
        this.currentPage.pageName = this.props.pageName;
        this.currentPage.pageType = this.props.pageType;
        if (this.props.pageType === EPageType.EXTENDED) {
            this.currentPage.pageHtml = this.props.basicData.localization.loading;
            fetch(this.props.pageHtml)
            .then((response:Response) => {
                if (response.status == 404) {
                    this.currentPage.pageHtml = this.props.basicData.localization.errorLoading;
                    this.forceUpdate();
                } else {
                    response.text().then((text) => {
                        this.currentPage.pageHtml = text;
                        this.forceUpdate();
                    });
                }
                
            });
            
        } else {
            this.currentPage.pageHtml = this.props.pageHtml;
        }
    }

    render() {
        return (
            <section id="main-content">
                <div className="inner-section">
                    <div className="red-background"></div>
                    <div className="content-3">
                        <div className="container pb-4">
                            <h1>{this.currentPage.pageName}</h1>
                        </div>
                    </div>
                </div>

                <div className="html pb-4 mt-3" dangerouslySetInnerHTML={{__html: this.currentPage.pageHtml}} />
            </section>
            );
    }
            
}