import * as React from 'react';
import IBasicData from '../interfaces/IBasicData';

export interface MainHeaderProps {
    activePage:string;
    basicData:IBasicData;
}

export class MainHeader extends React.Component<MainHeaderProps, {}> {

    private header: string;

    constructor(props: MainHeaderProps) {
        super(props);
    }

    render() {
        return(
            <header id="main-header" className="main-nav">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light main-nav">
                        <a href={this.props.basicData.root} className="navbar-brand"><img src="/static-assets/images/logo.png" style={{height: "35px"}} alt="Moravia Property R"/></a>
                        <button className="navbar-toggler" data-toggle="collapse" data-target="#mainnav">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="mainnav">
                            <ul className="navbar-nav ml-auto">
                                {
                                    this.props.basicData.pages.map((val) => {
                                        let pageHref = (val.href==="/"?"/":"/"+this.props.basicData.pagesRoot + "/" + val.href);
                                        if (val.showInNav) {
                                            return (
                                                <li className="nav-item" key={val.pageName}>
                                                    <a href={pageHref} className={"nav-link text-uppercase " + (this.props.activePage===(pageHref)?"active":"")}>
                                                        {val.pageName}
                                                    </a>
                                                </li>
                                            )
                                        } else return null;
                                    })
                                }
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }

}