import * as React from 'react';
import { MainProps } from '../MainComponent';

export class NotFoundPageComponent extends React.PureComponent<MainProps, {}> {

    constructor(props:MainProps) {
        super(props);
    }

    render() {
        return (
            <div className="text-center mt-5">
                <h1 className="text-danger font-weight-bold">404</h1>
                <h3 className="font-weight-light">{this.props.basicData.localization.notFound}</h3>
                <hr/>
            </div>
        );
    }
}